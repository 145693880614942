.pages-sorteo {
    background: #1a1a1a;
    min-height: 100vh;
}

  
  .div-tabla-detalles.detalles {
    background: #1a1a1a;
    border-radius: 10px;
    padding: 2px;
    width: 100%;
    margin: 0 auto;
  }
  
  .div-premios {
    width: 100% !important;
    height: auto !important;
    padding: 1rem 0;
  }
  
  .tabla-premios {
    gap: 1rem !important;
    padding: 0 1rem;
  }
  
  .div-tabla-detalles.ganadores {
    background: #1a1a1a;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    margin: 0 auto;
  }
  
  .tabla-ganadores {
    min-height: auto !important;
    max-height: 60vh;
    width: 100%;
    overflow-y: auto;
  }
  

  @media (max-width: 1024px) {
    .div-tabla-detalles.detalles {
      width: 300px;
      padding: 0.5rem;
    }
    
    .div-premios {
      padding: 0.5rem 0;
    }
  }
  
  @media (max-width: 768px) {
    .div-tabla-detalles.detalles {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .div-tabla-detalles.ganadores {
      width: 95%;
      margin: 1rem auto;
    }
  
    .tabla-ganadores {
      max-height: 50vh;
    }
  }
  
  @media (max-width: 480px) {
    .div-tabla-detalles.detalles img {
      width: 120px;
      height: auto;
    }
  
    .accordion-titulo {
      font-size: 0.8rem;
    }
  
    .accordion-contenido span {
      font-size: 0.75rem;
    }
  
    .react-tabs__tab {
      padding: 8px 12px;
      font-size: 0.8rem;
    }
  }
  
  .react-tabs__tab-list {
    background: #2d2d2d !important;
    border-radius: 8px 8px 0 0;
  }
  
  .react-tabs__tab--selected {
    background: #7f2e93 !important;
    border-color: #7f2e93 !important;
  }
  
  .accordion-item {
    background: #2d2d2d !important;
    border: 1px solid #3d3d3d !important;
  }
  
  .accordion-button:not(.collapsed) {
    background: #3d3d3d !important;
    color: #fff !important;
  }
  
  .accordion-body {
    padding: 0.5rem !important;
  }

.react-tabs__tab-list {
    @apply bg-gray-800 rounded-t-lg;
}

.react-tabs__tab {
    @apply text-white px-4 py-2 mr-1 rounded-t;
}

.react-tabs__tab--selected {
    @apply bg-purple-600 text-white;
}

.accordion-button:not(.collapsed) {
    @apply bg-gray-800 text-white;
}

.accordion-item {
    @apply bg-gray-800 border-gray-700;
}