
.comp-tablero {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bolita {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    margin-bottom: 5px;
    background: url('/public/images/bola_gris.png') !important;
    background-size: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 5px 0.2px black;
  }
  
  .bolita[data-number='0'] {
    opacity: 0 !important;
  }
  
  .bolita.ultima {
    background: url('/public/images/bola_rojo.png') !important;
    background-size: 100% !important;
    background-position: center !important;
  }
  
 
  .tablero-container {
    width: 95%;
    overflow-x: auto;
    padding: 5px 0;
    margin: 0 auto;
    max-width: 100%;
  }
  
  .bolitas-wrapper {
    display: flex;
    gap: 0.5rem;
    padding: 0 1rem;
    min-width: min-content;
  }
  

  @media screen and (max-width: 1024px) { 
    .bolita {
      width: 32px;
      height: 32px;
      font-size: 0.9em;
    }
  }
  
  @media screen and (max-width: 768px) { 
    .bolita {
      width: 30px;
      height: 30px;
      font-size: 0.85em;
      margin-bottom: 3px;
    }
    
    .bolitas-wrapper {
      gap: 0.4rem;
      padding: 0 0.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .bolita {
      width: 28px;
      height: 28px;
      font-size: 0.8em;
      box-shadow: 0px 0px 3px 0.1px black;
    }
    
    .bolitas-wrapper {
      gap: 0.3rem;
      padding: 0 0.5rem;
    }
  }
  
  @media screen and (max-width: 320px) { 
    .bolita {
      width: 25px;
      height: 25px;
      font-size: 0.75em;
    }
  }
  

  .tablero-container::-webkit-scrollbar {
    height: 4px;
  }
  
  .tablero-container::-webkit-scrollbar-thumb {
    background-color: #7f2e93;
    border-radius: 2px;
  }